import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from '@ui/utils/cn';
import Spinner from '../../spinner/Spinner';

const buttonVariants = cva(
  cn(
    'relative rounded-[4px] px-6 h-11 overflow-hidden isolate',
    'text-[13px] md:text-[14px] font-bold uppercase',
    'inline-flex items-center justify-center',
  ),
  {
    variants: {
      variant: {
        primary: cn(
          'bg-black border border-solid border-black text-white',
          'md:hover:after:absolute md:hover:after:inset-0 md:hover:after:z-10 md:hover:after:bg-[#E7E7E7]/10',
          'data-[disabled]:bg-[#F5F5F5] data-[disabled]:border-[#F5F5F5] data-[disabled]:text-[#9E9E9E]',
          'data-[loading]:bg-black',
        ),
        secondary: cn(
          'bg-white border border-solid border-black',
          'md:hover:bg-[#F6DBE7] md:hover:border-[#0D0005]',
          'md:active:bg-[#EEBFD4] md:hover:border-[#0D0005]',
          'data-[disabled]:bg-[#FFF] data-[disabled]:border-[#9E9E9E] data-[disabled]:text-[#9E9E9E]',
        ),
        outline: cn(
          'bg-white border border-solid border-black text-black',
          'md:hover:bg-[#F6DBE7] md:hover:border-[#140008]',
          'md:active:bg-[#EEBFD4] md:active:border-[#140008]',
          'data-[checked]:bg-[#EEBFD4] data-[checked]:border-[#140008]',
          'data-[disabled]:bg-transparent data-[disabled]:border-[#BDBDBD] data-[disabled]:text-[#BDBDBD]',
          'data-[muted]:bg-transparent data-[muted]:border-[#BDBDBD] data-[muted]:text-[#BDBDBD]',
        ),
        tertiary: cn(
          'h-auto',
          'bg-transparent border border-solid border-transparent',
          'md:hover:underline',
          'data-[disabled]:text-[#9E9E9E]',
          'data-[loading]:text-[#9E9E9E]',
        ),
        unstyled: cn('bg-transparent h-auto px-0 underline normal-case overflow-auto rounded-none font-normal'),
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disabled?: boolean;
  loading?: boolean;
  checked?: boolean;
  muted?: boolean;
  variant?: 'primary' | 'secondary' | 'outline' | 'tertiary' | 'unstyled';
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { asChild, children, loading, disabled, checked, muted, variant, className, rightIcon, leftIcon, ...props },
    ref,
  ) => {
    const Comp = !loading && asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        disabled={loading || disabled || undefined}
        data-disabled={loading || disabled || undefined}
        data-loading={loading}
        data-muted={muted || undefined}
        data-checked={checked || undefined}
        className={cn(buttonVariants({ variant, className }))}
        {...props}
      >
        {loading ? (
          <Spinner className="size-6" />
        ) : rightIcon || leftIcon ? (
          <>
            {leftIcon && leftIcon}
            <span>{children}</span>
            {rightIcon && rightIcon}
          </>
        ) : (
          children
        )}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };

import React from 'react';

const AccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06215 12.1647C5.86609 14.0407 3.8125 17.6464 3.8125 21.6492C3.8125 22.6568 4.54583 23.4485 5.47917 23.4485H22.1458C23.0792 23.4485 23.8125 22.6568 23.8125 21.6492C23.8125 17.6472 21.7597 14.0091 18.5648 12.1618C17.5155 13.7736 15.7782 14.8273 13.8125 14.8273C11.848 14.8273 10.1116 13.7749 9.06215 12.1647Z"
    />
    <path
      d="M9.06215 12.1647L9.43914 11.919L9.20467 11.5592L8.83435 11.7766L9.06215 12.1647ZM18.5648 12.1618L18.7901 11.7722L18.4205 11.5585L18.1877 11.9162L18.5648 12.1618ZM4.2625 21.6492C4.2625 17.7983 6.2381 14.3441 9.28995 12.5528L8.83435 11.7766C5.49409 13.7373 3.3625 17.4946 3.3625 21.6492H4.2625ZM5.47917 22.9985C4.82618 22.9985 4.2625 22.4414 4.2625 21.6492H3.3625C3.3625 22.8723 4.26549 23.8985 5.47917 23.8985V22.9985ZM22.1458 22.9985H5.47917V23.8985H22.1458V22.9985ZM23.3625 21.6492C23.3625 22.4414 22.7988 22.9985 22.1458 22.9985V23.8985C23.3595 23.8985 24.2625 22.8723 24.2625 21.6492H23.3625ZM18.3395 12.5513C21.3861 14.3129 23.3625 17.7969 23.3625 21.6492H24.2625C24.2625 17.4976 22.1334 13.7053 18.7901 11.7722L18.3395 12.5513ZM18.1877 11.9162C17.2125 13.4143 15.6103 14.3773 13.8125 14.3773V15.2773C15.9461 15.2773 17.8186 14.1329 18.9419 12.4073L18.1877 11.9162ZM13.8125 14.3773C12.0159 14.3773 10.4145 13.4155 9.43914 11.919L8.68516 12.4104C9.80875 14.1343 11.6802 15.2773 13.8125 15.2773V14.3773Z"
      fill="black"
    />
    <path
      d="M19.0059 9.64338C19.0059 12.5116 16.6807 14.8368 13.8125 14.8368C10.9443 14.8368 8.61913 12.5116 8.61913 9.64338C8.61913 6.77516 10.9443 4.45 13.8125 4.45C16.6807 4.45 19.0059 6.77516 19.0059 9.64338Z"
      stroke="black"
      strokeWidth="0.9"
    />
  </svg>
);

AccountIcon.defaultProps = {
  onClick: undefined,
};

export default AccountIcon;

import React from 'react';
import { Modal, Stack } from '../../../core';
import { Text } from '../../typography';
import { Button } from '@ui/components/shared';

type FinalSaleModalProps = {
  opened: boolean;
  onClose: () => void;
};

const FinalSaleModal = ({ opened, onClose }: FinalSaleModalProps): React.ReactElement => {
  const body = (
    <Stack
      pt="0"
      pb={{ base: 0, md: '1rem' }}
      px={{ base: 0, md: '1.75rem' }}
      spacing="0.75rem"
      sx={{
        '& p, & a': {
          color: 'rgba(0,0,0,0.7)',
          fontSize: '13px',
          lineHeight: '29px',
        },
        '& p': {
          textAlign: 'center',
        },
        '& b': {
          color: '#C90000',
          fontWeight: 'normal',
        },
      }}
    >
      <Text fz="13px" fw="700" align="center" tt="uppercase">
        Final Sale Details
      </Text>
      <p>
        Please note this item is marked as <b>FINAL SALE</b> and cannot be returned or exchanged unless faulty. <br />
        This applies to all products marked <b>FINAL SALE</b> and can include products listed with a sale price,
        intimates, earrings, and more. <br />
        Please see our{' '}
        <a href="/pages/returns" className="underline">
          Returns Policy{' '}
        </a>
        for more details.
      </p>
      <Button className="w-full md:text-[13px]" onClick={onClose}>
        Continue Shopping
      </Button>
    </Stack>
  );

  return (
    <Modal
      headerProps={{ pb: 0 }}
      body={body}
      opened={opened}
      onClose={onClose}
      withCloseButton
      withOverlay
      size="397px"
      centered
    />
  );
};

export default FinalSaleModal;

import React from 'react';
import { Menu } from '@mantine/core';
import {
  AccountIcon,
  Carousel,
  CompactCloseIcon,
  CompactRightChevronIcon,
  DownChevronIcon,
  Drawer,
  LeftArrowIcon,
  MyOrdersIcon,
  NextLink,
  SearchIcon,
  WishlistIcon,
} from '@ui/components/core';
import { Embla } from '@mantine/carousel';
import Link from 'next/link';
import useModalStore from '@ui/store/modalStore';
import { Button } from '../../buttons/Button/Button';
import cn from '@ui/utils/cn';
import { useStaticContent } from '@ui/providers/static-content-provider';
import type { MenuLink } from '@ui/providers/static-content-provider';
import env from '@ui/env';

const slideRight = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(-100%)' },
  transitionProperty: 'transform',
};

type MegaMenuDrawerProps = {
  isGuest: boolean;
  opened: boolean;
  onClose: () => void;
};

type MenuLinkItem = {
  label: string;
  href?: string;
  children?: number;
};

const MegaMenuDrawer = ({ isGuest, opened, onClose }: MegaMenuDrawerProps) => {
  const openModal = useModalStore((state) => state.openModal);
  const mobileMenuLinks = useStaticContent('Menu.MobileMenuLinkTree');
  const MenuLinkTable = mobileMenuLinks.reduce(
    (previousValue, currentLink) => {
      const newValue = { ...previousValue };

      const flattenLinks = (menuLink: MenuLink, parent: string) => {
        newValue[parent] ??= [];

        newValue[parent].push({
          label: menuLink.label,
          href: menuLink.href,
          children: menuLink.children?.length,
        });

        menuLink.children?.forEach((childLink) => {
          flattenLinks(childLink, `${parent}.${menuLink.label}`);
        });
      };

      flattenLinks(currentLink, '*');

      return newValue;
    },
    {} as Record<string, MenuLinkItem[]>,
  );

  const menuPaths = MenuLinkTable;
  const [embla, setEmbla] = React.useState<Embla | null>(null);
  const [activePaths, setActivePaths] = React.useState<string[]>(['*']);

  const body = (
    <>
      <div className="p-4 flex justify-center">
        <button
          onClick={onClose}
          className="size-10 bg-white rounded-[4px] border-[0.6px] border-black flex items-center justify-center"
        >
          <CompactCloseIcon width={10} height={10} />
        </button>
      </div>
      <Carousel
        classNames={{
          root: 'h-full w-[280px] bg-white',
          viewport: 'h-full',
          container: 'h-full',
        }}
        withControls={false}
        draggable={false}
        getEmblaApi={setEmbla}
        speed={20}
      >
        {activePaths.map((activePath) => (
          <Carousel.Slide className="h-full relative" key={activePath}>
            <div className="h-full max-h-screen pb-16 overflow-y-hidden pl-4 relative">
              <div className="space-y-3 h-full overflow-y-auto pt-4 pr-4 pb-6">
                {!!(activePath !== '*') && (
                  <div className="flex items-center justify-center h-10 relative">
                    <button
                      className="flex items-center justify-center left-0 absolute"
                      onClick={() => embla?.scrollPrev()}
                    >
                      <LeftArrowIcon width={16} height={16} />
                    </button>
                    <div className="text-[13px] tracking-[0.04em]">{activePath.split('.').pop()?.toUpperCase()}</div>
                  </div>
                )}
                {menuPaths[activePath].map((menuLink) =>
                  menuLink.children ? (
                    <button
                      key={menuLink.label}
                      className="w-full flex items-center justify-between text-left p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-10"
                      onClick={() => {
                        const newPath = `${activePath}.${menuLink.label}`;

                        const newActivePaths = activePaths
                          .filter((path) => path.split('.').length < newPath.split('.').length)
                          .map((path) => path);

                        newActivePaths.push(newPath);

                        setActivePaths(newActivePaths);

                        setTimeout(() => embla?.scrollNext(), 1);
                      }}
                    >
                      <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{menuLink.label}</span>
                      <CompactRightChevronIcon width={12} height={12} />
                    </button>
                  ) : (
                    <NextLink
                      key={menuLink.label}
                      className="w-full flex items-center justify-between text-left p-2 border-[0.6px] border-black bg-[#FCF1F5] rounded-[4px] text-xs h-[38px]"
                      href={menuLink.href || ''}
                    >
                      <span className={activePath === '*' ? 'uppercase' : 'capitalize'}>{menuLink.label}</span>
                    </NextLink>
                  ),
                )}
              </div>
              {activePath === '*' && (
                <div className="absolute bottom-16 left-0 w-full bg-gradient-to-b from-transparent to-white to-[76.83%] h-6"></div>
              )}
            </div>
            {activePath === '*' && (
              <div className={cn('flex space-x-2 items-center justify-between', 'p-4 absolute bottom-0 inset-x-0')}>
                {isGuest ? (
                  <Button className="!text-[10px] w-full h-[38px]" asChild>
                    <Link href="/account/login/"> LOGIN / SIGN UP</Link>
                  </Button>
                ) : (
                  <>
                    <Link
                      href="/account/"
                      className="border border-black rounded h-[38px] w-[38px] flex items-center justify-center bg-white"
                    >
                      <AccountIcon width={24} height={24} />
                    </Link>
                    <Link
                      href="/account/orders/"
                      className="border border-black rounded h-[38px] w-[38px] flex items-center justify-center bg-white"
                    >
                      <MyOrdersIcon width={24} height={24} />
                    </Link>
                    <button
                      className="border border-black rounded h-[38px] w-[38px] flex items-center justify-center bg-white"
                      onClick={() => openModal('wishlist')}
                      data-modal-toggle
                    >
                      <WishlistIcon width={24} height={24} />
                    </button>
                  </>
                )}
                <Menu
                  width={72}
                  position="top"
                  classNames={{
                    dropdown: 'px-0 py-1 rounded-none shadow-none border-black bg-white !ml-0',
                    item: cn(
                      'px-3 py-1 text-[12px] text-center rounded-none underline-offset-[6px]',
                      'data-[hovered]:underline data-[hovered]:bg-white',
                    ),
                  }}
                >
                  <Menu.Target>
                    <button
                      className={cn(
                        'flex items-center justify-center h-[38px] w-[72px] shrink-0',
                        'text-[10px] font-normal space-x-2 group rounded-sm',
                        'border border-black rounded-[4px]',
                        'focus:outline-none',
                      )}
                    >
                      {env.NEXT_PUBLIC_REGION === 'NZ' && <span>$NZD</span>}
                      {env.NEXT_PUBLIC_REGION === 'AU' && <span>$AUD</span>}
                      {env.NEXT_PUBLIC_REGION === 'US' && <span>$USD</span>}
                      <DownChevronIcon className="group-data-[expanded]:rotate-180 transition" width={16} height={16} />
                    </button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item className={env.NEXT_PUBLIC_REGION === 'AU' ? 'underline' : ''}>
                      {!(env.NEXT_PUBLIC_REGION === 'AU') ? (
                        <Link href="https://www.hellomolly.com.au/">$AUD</Link>
                      ) : (
                        '$AUD'
                      )}
                    </Menu.Item>
                    <Menu.Item className={env.NEXT_PUBLIC_REGION === 'NZ' ? 'underline' : ''}>
                      {!(env.NEXT_PUBLIC_REGION === 'NZ') ? (
                        <Link href="https://www.hellomolly.co.nz/">$NZD</Link>
                      ) : (
                        '$NZD'
                      )}
                    </Menu.Item>
                    <Menu.Item className={env.NEXT_PUBLIC_REGION === 'US' ? 'underline' : ''}>
                      {!(env.NEXT_PUBLIC_REGION === 'US') ? (
                        <Link href="https://www.hellomolly.com/">$USD</Link>
                      ) : (
                        '$USD'
                      )}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            )}
          </Carousel.Slide>
        ))}
      </Carousel>
    </>
  );

  return (
    <Drawer
      withOverlay
      body={body}
      opened={opened}
      onClose={onClose}
      transitionProps={{
        duration: 200,
        timingFunction: 'ease',
        transition: slideRight,
      }}
      classNames={{
        root: 'lg:hidden',
        overlay: 'bg-black/20',
        inner: 'w-[352px]',
        content: 'flex flex-col h-full mt-0 bg-transparent shadow-none',
        body: 'h-full p-0 flex flex-row-reverse',
      }}
    />
  );
};

export default MegaMenuDrawer;

import { cloneDeep } from 'lodash';
import {
  SwimAllIcon,
  SwimBandeauIcon,
  SwimBraletteIcon,
  SwimCheekyIcon,
  SwimClassicIcon,
  SwimHighCutIcon,
  SwimOnePieceIcon,
  SwimScoopNeckIcon,
  SwimTieUpIcon,
  SwimTriangleIcon,
  SwimUnderwireIcon,
} from '@ui/components/core';
import { CollectionFilterMappingType } from '@ui/providers/static-content-provider';

export const MegaMenuHeadingLabels: Array<string> = [
  'New In',
  'Dresses',
  'Clothing',
  'Back in Stock',
  'Shoes',
  'Accessories',
  'Swim',
  'Exclusives',
  'Sale',
];

export const MegaMenuHeaderDropdownHeadingLabels: {
  [key: string]: Array<string>;
} = {
  Dresses: ['Shop by Style', '', 'Shop by Occasion', ''],
  Clothing: ['Dresses', '', 'Playsuits', 'Tops', 'Bottoms', '', 'Swim'],
};

export const MegaMenuDropdownLabels: { [key: string]: Array<Array<string>> } = {
  'New In': [
    ['All New', 'New Dresses', 'New Playsuits', 'New Tops', 'New Bottoms', 'New Swim', 'New Shoes'],
    ['New Accessories', 'New Sets & Co-Ords', 'New Exclusives'],
  ],
  Dresses: [
    [
      'All Dresses',
      'Mini Dresses',
      'Midi Dresses',
      'Maxi Dresses',
      'Slip Dresses',
      'Sequin Dresses',
      'Bodycon Dresses',
    ],
    [
      'Printed Dresses',
      'Backless Dresses',
      'Off The Shoulder Dresses',
      'Long Sleeve Dresses',
      'Lace Dresses',
      'Party Dresses',
    ],
    [
      'Bridesmaid & Wedding Guest',
      'Formal & Cocktail',
      'Beach & Holiday',
      'Brunch',
      'Date Night',
      'Day Dresses',
      'Festival',
    ],
    ['Racing', 'Work'],
  ],
  Clothing: [
    [
      'All Dresses',
      'Mini Dresses',
      'Midi Dresses',
      'Maxi Dresses',
      'Slip Dresses',
      'Sequin Dresses',
      'Bodycon Dresses',
    ],
    [
      'Printed Dresses',
      'Backless Dresses',
      'Off The Shoulder Dresses',
      'Long Sleeve Dresses',
      'Lace Dresses',
      'Party Dresses',
    ],
    ['All Playsuits', 'Party Playsuits', 'Printed Playsuits', 'Jumpsuits'],
    [
      'All Tops',
      'Crop Tops',
      'Sleeveless Tops',
      'Short Sleeved Tops',
      'Long Sleeved Tops',
      'Basics',
      'Bodysuits & Bralets',
      'Blazers',
      'Knits',
      'Sets & Co-Ords',
      'Outerwear',
    ],
    [
      'All Bottoms',
      'All Skirts',
      'Mini Skirts',
      'Midi Skirts',
      'Maxi Skirts',
      'Denim Skirts',
      'Skorts',
      'All Shorts',
      'Denim Shorts',
      'Fashion Shorts',
      'All Pants',
      'Leggings',
    ],
    ['Skinny Pants', 'Tailored Pants', 'Wide Leg Pants', 'Jeans', 'Sets & Co-Ords'],
    ['All Swim', 'One-Pieces', 'Swim Tops', 'Swim Bottoms', 'Cover Ups', 'Swim Accessories'],
  ],
  Playsuits: [['All Playsuits', 'Party Playsuits', 'Printed Playsuits', 'Jumpsuits']],
  Tops: [
    [
      'All Tops',
      'Crop Tops',
      'Sleeveless Tops',
      'Short Sleeved Tops',
      'Long Sleeved Tops',
      'Basics',
      'Bodysuits & Bralets',
      'Blazers',
    ],
    ['Knits', 'Sets & Co-Ords', 'Outerwear'],
  ],
  Bottoms: [
    ['All Bottoms'],
    ['All Skirts', 'Mini Skirts', 'Midi Skirts', 'Maxi Skirts', 'Denim Skirts'],
    ['All Shorts', 'Denim Shorts', 'Fashion Shorts'],
    ['All Pants', 'Leggings', 'Skinny Pants', 'Tailored Pants', 'Wide Leg Pants', 'Jeans', 'Sets & Co-Ords'],
  ],
  'Back in Stock': [
    [
      'All Back in Stock',
      'Back in Dresses',
      'Back in Playsuits',
      'Back in Tops',
      'Back in Bottoms',
      'Back in Accessories',
      'Back in Swim',
    ],
  ],
  Shoes: [['All Shoes'], ['Heels', 'Mules', 'Slides', 'Boots', 'Sandals', 'Flats']],
  Accessories: [
    ['All Accessories'],
    ['All Jewellery', 'Necklaces', 'Earrings', 'Bracelets', 'Rings'],
    ['Breast Styling Essentials', 'Beauty & Wellness', 'Bags', 'Sunglasses', 'Hair & Headwear', 'Swim Accessories'],
  ],
  Sale: [
    ['All Sale', 'New Added To Sale', 'Sale Dresses', 'Sale Playsuits', 'Sale Tops', 'Sale Bottoms', 'Sale Shoes'],
    ['Sale Accessories', 'Sale Swim', 'Final Sale'],
  ],
  Swim: [['All Swim', 'One-Pieces', 'Swim Tops', 'Swim Bottoms', 'Cover Ups', 'Swim Accessories']],
  Exclusives: [
    [
      'All Exclusives',
      'Hello Molly Exclusive',
      'Dear Emilia',
      'Sur Belle',
      'Hello Molly Swim',
      'Undercover Style Helpers',
      'Hello Mini',
      'Wedding Parlour',
      'Hello Molly BASE',
      'Hello Molly DNM',
    ],
  ],
};

export type MenuLink = {
  label: string;
  href?: string;
  column?: number;
  children?: Array<MenuLink>;
  upsell?: Array<{
    imageUrl: string;
    productUrl: string;
    productTitle: string;
  }>;
};

export const DesktopMenuLinkTree: MenuLink[] = [
  {
    label: 'New In',
    href: '/collections/new/',
    children: [
      { label: 'All New', href: '/collections/new/', column: 1 },
      {
        label: 'New Dresses',
        href: '/collections/new/category_dresses/',
        column: 1,
      },
      {
        label: 'New Playsuits',
        href: '/collections/new/category_playsuits/',
        column: 1,
      },
      { label: 'New Tops', href: '/collections/new/category_tops/', column: 1 },
      {
        label: 'New Bottoms',
        href: '/collections/new/category_bottoms/',
        column: 1,
      },
      { label: 'New Swim', href: '/collections/new/category_swim/', column: 1 },
      {
        label: 'New Shoes',
        href: '/collections/new/category_shoes/',
        column: 1,
      },
      {
        label: 'New Accessories',
        href: '/collections/new/category_accessories/',
        column: 2,
      },
      {
        label: 'New Sets & Co-Ords',
        href: '/collections/new/category_co-ords/',
        column: 2,
      },
      {
        label: 'New Exclusives',
        href: '/collections/new/category_exclusive/',
        column: 2,
      },
    ],
  },
  {
    label: 'Dresses',
    href: '/collections/dresses/',
    children: [
      {
        label: 'Shop by Style',
        children: [
          { label: 'All Dresses', href: '/collections/dresses/', column: 1 },
          {
            label: 'Mini Dresses',
            href: '/collections/mini-dresses/',
            column: 1,
          },
          {
            label: 'Midi Dresses',
            href: '/collections/midi-dresses/',
            column: 1,
          },
          {
            label: 'Maxi Dresses',
            href: '/collections/maxi-dresses/',
            column: 1,
          },
          {
            label: 'Slip Dresses',
            href: '/collections/slip-dresses/',
            column: 1,
          },
          {
            label: 'Sequin Dresses',
            href: '/collections/sequin-dress/',
            column: 1,
          },
          {
            label: 'Bodycon Dresses',
            href: '/collections/bodycon-dress/',
            column: 1,
          },
          {
            label: 'Printed Dresses',
            href: '/collections/printed-dresses/',
            column: 2,
          },
          {
            label: 'Backless Dresses',
            href: '/collections/backless-dress/',
            column: 2,
          },
          {
            label: 'Off The Shoulder Dresses',
            href: '/collections/off-the-shoulder-dress/',
            column: 2,
          },
          {
            label: 'Long Sleeve Dresses',
            href: '/collections/long-sleeve-dress/',
            column: 2,
          },
          {
            label: 'Lace Dresses',
            href: '/collections/lace-dress/',
            column: 2,
          },
          {
            label: 'Party Dresses',
            href: '/collections/party-dresses/',
            column: 2,
          },
        ],
      },
      {
        label: 'Shop by Occasion',
        children: [
          {
            label: 'Bridesmaid & Wedding Guest',
            href: '/collections/dresses/occasion_bridesmaid-&-wedding-guest/',
            column: 1,
          },
          {
            label: 'Formal & Cocktail',
            href: '/collections/dresses/occasion_formal-&-cocktail/',
            column: 1,
          },
          {
            label: 'Beach & Holiday',
            href: '/collections/dresses/occasion_beach-&-holiday/',
            column: 1,
          },
          {
            label: 'Brunch',
            href: '/collections/dresses/occasion_brunch/',
            column: 1,
          },
          {
            label: 'Date Night',
            href: '/collections/dresses/occasion_date-night/',
            column: 1,
          },
          {
            label: 'Day Dresses',
            href: '/collections/day-dresses/',
            column: 1,
          },
          {
            label: 'Festival',
            href: '/collections/dresses/occasion_festival/',
            column: 1,
          },
          {
            label: 'Racing',
            href: '/collections/dresses/occasion_racing/',
            column: 2,
          },
          {
            label: 'Work',
            href: '/collections/dresses/occasion_work/',
            column: 2,
          },
        ],
      },
    ],
    upsell: [
      {
        imageUrl: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/A23I0306_-_resized.jpg?v=1722566443',
        productUrl: '/collections/dresses/products/indigo-fields-satin-maxi-dress-blue',
        productTitle: 'HELLO MOLLY Indigo Fields Satin Maxi Dress Blue',
      },
      {
        imageUrl: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/L4A5677_-_resized.jpg?v=1722566443',
        productUrl: '/collections/dresses/products/crystal-glitz-maxi-dress-black',
        productTitle: 'HELLO MOLLY Crystal Glitz Maxi Dress Black',
      },
    ],
  },
  {
    label: 'Clothing',
    href: '/collections/clothing/',
    children: [
      {
        label: 'Dresses',
        children: [
          { label: 'All Dresses', href: '/collections/dresses/', column: 1 },
          {
            label: 'Formal Dresses',
            href: '/collections/formal-dresses/',
            column: 1,
          },
          {
            label: 'Mini Dresses',
            href: '/collections/mini-dresses/',
            column: 1,
          },
          {
            label: 'Midi Dresses',
            href: '/collections/midi-dresses/',
            column: 1,
          },
          {
            label: 'Maxi Dresses',
            href: '/collections/maxi-dresses/',
            column: 1,
          },
          {
            label: 'Slip Dresses',
            href: '/collections/slip-dresses/',
            column: 1,
          },
          {
            label: 'Sequin Dresses',
            href: '/collections/sequin-dress/',
            column: 1,
          },
          {
            label: 'Bodycon Dresses',
            href: '/collections/bodycon-dress/',
            column: 1,
          },
          {
            label: 'Printed Dresses',
            href: '/collections/printed-dresses/',
            column: 2,
          },
          {
            label: 'Backless Dresses',
            href: '/collections/backless-dress/',
            column: 2,
          },
          {
            label: 'Off The Shoulder Dresses',
            href: '/collections/off-the-shoulder-dress/',
            column: 2,
          },
          {
            label: 'Long Sleeve Dresses',
            href: '/collections/long-sleeve-dress/',
            column: 2,
          },
          {
            label: 'Lace Dresses',
            href: '/collections/lace-dress/',
            column: 2,
          },
          {
            label: 'Party Dresses',
            href: '/collections/party-dresses/',
            column: 2,
          },
        ],
      },
      {
        label: 'Playsuits',
        children: [
          { label: 'All Playsuits', href: '/collections/playsuits/' },
          { label: 'Party Playsuits', href: '/collections/party-playsuits/' },
          {
            label: 'Printed Playsuits',
            href: '/collections/printed-playsuits/',
          },
          { label: 'Jumpsuits', href: '/collections/jumpsuits/' },
        ],
      },
      {
        label: 'Tops',
        children: [
          { label: 'All Tops', href: '/collections/tops/' },
          { label: 'Crop Tops', href: '/collections/crop-tops/' },
          { label: 'Sleeveless Tops', href: '/collections/sleeveless-tops/' },
          {
            label: 'Short Sleeved Tops',
            href: '/collections/short-sleeved-tops/',
          },
          {
            label: 'Long Sleeved Tops',
            href: '/collections/long-sleeved-tops/',
          },
          { label: 'Basics', href: '/collections/basics/' },
          {
            label: 'Bodysuits & Bralets',
            href: '/collections/bodysuits-bralets/',
          },
          { label: 'Blazers', href: '/collections/blazers/' },
          { label: 'Knits', href: '/collections/knits/' },
          { label: 'Sets & Co-Ords', href: '/collections/sets/' },
          { label: 'Outerwear', href: '/collections/outerwear/' },
        ],
      },
      {
        label: 'Bottoms',
        children: [
          { label: 'All Bottoms', href: '/collections/bottoms/', column: 1 },
          { label: 'All Skirts', href: '/collections/skirts/', column: 1 },
          {
            label: 'Mini Skirts',
            href: '/collections/skirts/length_mini/',
            column: 1,
          },
          {
            label: 'Midi Skirts',
            href: '/collections/skirts/length_midi/',
            column: 1,
          },
          {
            label: 'Maxi Skirts',
            href: '/collections/skirts/length_maxi/',
            column: 1,
          },
          {
            label: 'Denim Skirts',
            href: '/collections/skirts/color_denim/',
            column: 1,
          },
          {
            label: 'Skorts',
            href: '/collections/skorts/',
            column: 1,
          },
          { label: 'All Shorts', href: '/collections/shorts/', column: 1 },
          {
            label: 'Denim Shorts',
            href: '/collections/shorts/color_denim/',
            column: 1,
          },
          {
            label: 'Fashion Shorts',
            href: '/collections/fashion-shorts/',
            column: 1,
          },
          { label: 'All Pants', href: '/collections/pants/', column: 1 },
          { label: 'Leggings', href: '/collections/leggings/', column: 1 },
          {
            label: 'Skinny Pants',
            href: '/collections/skinny-pants/',
            column: 2,
          },
          {
            label: 'Tailored Pants',
            href: '/collections/tailored-pants/',
            column: 2,
          },
          {
            label: 'Wide Leg Pants',
            href: '/collections/wide-leg-pants/',
            column: 2,
          },
          { label: 'Jeans', href: '/collections/jeans/', column: 2 },
          { label: 'Sets & Co-Ords', href: '/collections/sets/', column: 2 },
        ],
      },
      {
        label: 'Swim',
        children: [
          { label: 'All Swim', href: '/collections/swim/' },
          { label: 'One-Pieces', href: '/collections/one-pieces/' },
          { label: 'Swim Tops', href: '/collections/swim-tops/' },
          { label: 'Swim Bottoms', href: '/collections/swim-bottoms/' },
          { label: 'Cover Ups', href: '/collections/cover-ups/' },
          { label: 'Swim Accessories', href: '/collections/swim-accessories/' },
        ],
      },
    ],
  },
  {
    label: 'Back in Stock',
    href: '/collections/back-in-stock/',
    children: [
      { label: 'All Back in Stock', href: '/collections/back-in-stock/' },
      {
        label: 'Back in Dresses',
        href: '/collections/back-in-stock/category_dresses/',
      },
      {
        label: 'Back in Playsuits',
        href: '/collections/back-in-stock/category_playsuits/',
      },
      {
        label: 'Back in Tops',
        href: '/collections/back-in-stock/category_tops/',
      },
      {
        label: 'Back in Bottoms',
        href: '/collections/back-in-stock/category_bottoms/',
      },
      {
        label: 'Back in Accessories',
        href: '/collections/back-in-stock/category_accessories/',
      },
      {
        label: 'Back in Swim',
        href: '/collections/back-in-stock/category_swim/',
      },
    ],
  },
  {
    label: 'Shoes',
    href: '/collections/shoes/',
    children: [
      { label: 'All Shoes', href: '/collections/shoes/', column: 1 },
      { label: 'Heels', href: '/collections/heels/', column: 2 },
      { label: 'Mules', href: '/collections/mules/', column: 2 },
      { label: 'Slides', href: '/collections/slides/', column: 2 },
      { label: 'Boots', href: '/collections/boots/', column: 2 },
      { label: 'Sandals', href: '/collections/sandals/', column: 2 },
      { label: 'Flats', href: '/collections/flats/', column: 2 },
    ],
  },
  {
    label: 'Accessories',
    href: '/collections/accessories/',
    children: [
      {
        label: 'All Accessories',
        href: '/collections/accessories/',
        column: 1,
      },
      { label: 'All Jewellery', href: '/collections/jewellery/', column: 2 },
      { label: 'Necklaces', href: '/collections/necklaces/', column: 2 },
      { label: 'Earrings', href: '/collections/earrings/', column: 2 },
      { label: 'Bracelets', href: '/collections/bracelets/', column: 2 },
      { label: 'Rings', href: '/collections/rings/', column: 2 },
      { label: 'Self Love', href: '/collections/self-love/', column: 2 },
      {
        label: 'Breast Styling Essentials',
        href: '/collections/accessories/category_magic-bra/',
        column: 3,
      },
      { label: 'Beauty & Wellness', href: '/collections/beauty/', column: 3 },
      { label: 'Bags', href: '/collections/bags/', column: 3 },
      { label: 'Sunglasses', href: '/collections/sunglasses/', column: 3 },
      { label: 'Hair & Headwear', href: '/collections/headwear/', column: 3 },
      {
        label: 'Swim Accessories',
        href: '/collections/swim-accessories/',
        column: 3,
      },
    ],
  },
  {
    label: 'Swim',
    href: '/collections/swim/',
    children: [
      { label: 'All Swim', href: '/collections/swim/' },
      { label: 'One-Pieces', href: '/collections/one-pieces/' },
      { label: 'Swim Tops', href: '/collections/swim-tops/' },
      { label: 'Swim Bottoms', href: '/collections/swim-bottoms/' },
      { label: 'Cover Ups', href: '/collections/cover-ups/' },
      { label: 'Swim Accessories', href: '/collections/swim-accessories/' },
    ],
  },
  {
    label: 'Exclusives',
    href: '/collections/exclusive/',
    children: [
      { label: 'All Exclusives', href: '/collections/exclusive/', column: 1 },
      {
        label: 'Hello Molly Exclusive',
        href: '/collections/exclusive/brand_hello-molly-exclusive/',
        column: 1,
      },
      {
        label: 'Dear Emilia',
        href: '/collections/exclusive/brand_dear-emilia/',
        column: 1,
      },
      {
        label: 'Sur Belle',
        href: '/collections/exclusive/brand_sur-belle/',
        column: 1,
      },
      {
        label: 'Hello Molly Swim',
        href: '/collections/swim/brand_hello-molly/',
        column: 1,
      },
      {
        label: 'Undercover Style Helpers',
        href: '/collections/accessories/brand_undercover-style-helpers/',
        column: 1,
      },
      {
        label: 'Hello Mini',
        href: '/collections/exclusive/brand_hello-mini/',
        column: 1,
      },
      {
        label: 'Wedding Parlour',
        href: '/collections/wedding-parlour/',
        column: 2,
      },
      {
        label: 'Hello Molly BASE',
        href: '/collections/hello-molly-basics/',
        column: 2,
      },
      {
        label: 'Hello Molly DNM',
        href: '/collections/hello-molly/trend_denim/',
        column: 2,
      },
    ],
  },
  {
    label: 'Sale',
    href: '/collections/sale/',
    children: [
      { label: 'All Sale', href: '/collections/sale/', column: 1 },
      {
        label: 'New Added To Sale',
        href: '/collections/sale/category_new/',
        column: 1,
      },
      {
        label: 'Sale Dresses',
        href: '/collections/sale/category_dresses/',
        column: 1,
      },
      {
        label: 'Sale Playsuits',
        href: '/collections/sale/category_playsuits/',
        column: 1,
      },
      {
        label: 'Sale Tops',
        href: '/collections/sale/category_tops/',
        column: 1,
      },
      {
        label: 'Sale Bottoms',
        href: '/collections/sale/category_bottoms/',
        column: 1,
      },
      {
        label: 'Sale Shoes',
        href: '/collections/sale/category_shoes/',
        column: 1,
      },
      {
        label: 'Sale Accessories',
        href: '/collections/sale/category_accessories/',
        column: 2,
      },
      {
        label: 'Sale Swim',
        href: '/collections/sale/category_swim/',
        column: 2,
      },
      {
        label: 'Final Sale',
        href: '/collections/sale/category_final-sale/',
        column: 2,
      },
    ],
  },
  { label: 'Night Out', href: '/collections/night-out/' },
].filter(Boolean) as Array<MenuLink>;

export const MobileMenuLinkTree: MenuLink[] = cloneDeep(DesktopMenuLinkTree).map((menuLink) => {
  if (menuLink.label !== 'Dresses') return menuLink;

  const [shopByStyle, shopByOccassion] = menuLink.children as Array<MenuLink & { children: MenuLink[] }>;

  return { ...menuLink, children: [...shopByStyle.children, shopByOccassion] };
});

export const CategoryFilterMapping: { [key: string]: string[] } = {
  // Category New
  '/collections/new/': flattenChildLinkLabels('New In'),
  '/collections/new/category_dresses/': flattenChildLinkLabels('New In'),
  '/collections/new/category_playsuits/': flattenChildLinkLabels('New In'),
  '/collections/new/category_tops/': flattenChildLinkLabels('New In'),
  '/collections/new/category_bottoms/': flattenChildLinkLabels('New In'),
  '/collections/new/category_swim/': flattenChildLinkLabels('New In'),
  '/collections/new/category_shoes/': flattenChildLinkLabels('New In'),
  '/collections/new/category_accessories/': flattenChildLinkLabels('New In'),
  '/collections/new/category_co-ords/': flattenChildLinkLabels('New In'),
  '/collections/new/category_exclusive/': flattenChildLinkLabels('New In'),

  // Category Dresses
  '/collections/dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/mini-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/midi-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/maxi-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/slip-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/sequin-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/bodycon-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/printed-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/backless-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/off-the-shoulder-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/long-sleeve-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/lace-dress/': flattenChildLinkLabels('Dresses'),
  '/collections/party-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_bridesmaid-&-wedding-guest/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_formal-&-cocktail/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_beach-&-holiday/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_brunch/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_date-night/': flattenChildLinkLabels('Dresses'),
  '/collections/day-dresses/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_festival/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_racing/': flattenChildLinkLabels('Dresses'),
  '/collections/dresses/occasion_work/': flattenChildLinkLabels('Dresses'),

  // Category Clothing
  '/collections/clothing/': flattenChildLinkLabels('Clothing'),
  '/collections/playsuits/': flattenChildLinkLabels('Clothing'),
  '/collections/party-playsuits/': flattenChildLinkLabels('Clothing'),
  '/collections/printed-playsuits/': flattenChildLinkLabels('Clothing'),
  '/collections/jumpsuits/': flattenChildLinkLabels('Clothing'),
  '/collections/tops/': flattenChildLinkLabels('Clothing'),
  '/collections/crop-tops/': flattenChildLinkLabels('Clothing'),
  '/collections/sleeveless-tops/': flattenChildLinkLabels('Clothing'),
  '/collections/short-sleeved-tops/': flattenChildLinkLabels('Clothing'),
  '/collections/long-sleeved-tops/': flattenChildLinkLabels('Clothing'),
  '/collections/basics/': flattenChildLinkLabels('Clothing'),
  '/collections/bodysuits-bralets/': flattenChildLinkLabels('Clothing'),
  '/collections/blazers/': flattenChildLinkLabels('Clothing'),
  '/collections/knits/': flattenChildLinkLabels('Clothing'),
  '/collections/sets/': flattenChildLinkLabels('Clothing'),
  '/collections/outerwear/': flattenChildLinkLabels('Clothing'),
  '/collections/bottoms/': flattenChildLinkLabels('Clothing'),
  '/collections/skirts/': flattenChildLinkLabels('Clothing'),
  '/collections/skirts/length_mini/': flattenChildLinkLabels('Clothing'),
  '/collections/skirts/length_midi/': flattenChildLinkLabels('Clothing'),
  '/collections/skirts/length_maxi/': flattenChildLinkLabels('Clothing'),
  '/collections/skirts/color_denim/': flattenChildLinkLabels('Clothing'),
  '/collections/skorts/': flattenChildLinkLabels('Clothing'),
  '/collections/shorts/': flattenChildLinkLabels('Clothing'),
  '/collections/shorts/color_denim/': flattenChildLinkLabels('Clothing'),
  '/collections/fashion-shorts/': flattenChildLinkLabels('Clothing'),
  '/collections/pants/': flattenChildLinkLabels('Clothing'),
  '/collections/leggings/': flattenChildLinkLabels('Clothing'),
  '/collections/skinny-pants/': flattenChildLinkLabels('Clothing'),
  '/collections/tailored-pants/': flattenChildLinkLabels('Clothing'),
  '/collections/wide-leg-pants/': flattenChildLinkLabels('Clothing'),
  '/collections/jeans/': flattenChildLinkLabels('Clothing'),

  // Category Back in Stock
  '/collections/back-in-stock/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_dresses/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_playsuits/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_tops/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_bottoms/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_accessories/': flattenChildLinkLabels('Back in Stock'),
  '/collections/back-in-stock/category_swim/': flattenChildLinkLabels('Back in Stock'),

  // Category Shoes
  '/collections/shoes/': flattenChildLinkLabels('Shoes'),
  '/collections/heels/': flattenChildLinkLabels('Shoes'),
  '/collections/mules/': flattenChildLinkLabels('Shoes'),
  '/collections/slides/': flattenChildLinkLabels('Shoes'),
  '/collections/boots/': flattenChildLinkLabels('Shoes'),
  '/collections/sandals/': flattenChildLinkLabels('Shoes'),
  '/collections/flats/': flattenChildLinkLabels('Shoes'),

  // Category Accessories
  '/collections/accessories/': flattenChildLinkLabels('Accessories'),
  '/collections/jewellery/': flattenChildLinkLabels('Accessories'),
  '/collections/necklaces/': flattenChildLinkLabels('Accessories'),
  '/collections/earrings/': flattenChildLinkLabels('Accessories'),
  '/collections/bracelets/': flattenChildLinkLabels('Accessories'),
  '/collections/rings/': flattenChildLinkLabels('Accessories'),
  '/collections/accessories/category_magic-bra/': flattenChildLinkLabels('Accessories'),
  '/collections/beauty/': flattenChildLinkLabels('Accessories'),
  '/collections/bags/': flattenChildLinkLabels('Accessories'),
  '/collections/sunglasses/': flattenChildLinkLabels('Accessories'),
  '/collections/headwear/': flattenChildLinkLabels('Accessories'),
  '/collections/self-love/': flattenChildLinkLabels('Accessories'),

  // Category Swim
  '/collections/swim/': flattenChildLinkLabels('Swim'),
  '/collections/one-pieces/': flattenChildLinkLabels('Swim'),
  '/collections/swim-tops/': flattenChildLinkLabels('Swim'),
  '/collections/swim-bottoms/': flattenChildLinkLabels('Swim'),
  '/collections/cover-ups/': flattenChildLinkLabels('Swim'),
  '/collections/swim-accessories/': flattenChildLinkLabels('Swim'),

  // Category Exclusives
  '/collections/exclusive/': flattenChildLinkLabels('Exclusives'),
  '/collections/exclusive/brand_hello-molly/': flattenChildLinkLabels('Exclusives'),
  '/collections/exclusive/brand_dear-emilia/': flattenChildLinkLabels('Exclusives'),
  '/collections/exclusive/brand_sur-belle/': flattenChildLinkLabels('Exclusives'),
  '/collections/swim/brand_hello-molly/': flattenChildLinkLabels('Exclusives'),
  '/collections/accessories/brand_undercover-style-helpers/': flattenChildLinkLabels('Exclusives'),
  '/collections/exclusive/brand_hello-mini/': flattenChildLinkLabels('Exclusives'),
  '/collections/wedding-parlour/': flattenChildLinkLabels('Exclusives'),
  '/collections/wedding-parlour/category_bridesmaids/': flattenChildLinkLabels('Exclusives'),
  '/collections/wedding-parlour/category_bride/': flattenChildLinkLabels('Exclusives'),
  '/collections/wedding-parlour/category_bachelorette/': flattenChildLinkLabels('Exclusives'),
  '/collections/wedding-parlour/categor_wedding-guest/': flattenChildLinkLabels('Exclusives'),
  '/collections/hello-molly-basics/': flattenChildLinkLabels('Exclusives'),
  '/collections/hello-molly/trend_denim': flattenChildLinkLabels('Exclusives'),

  // Category Sales
  '/collections/sale/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_new/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_dresses/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_playsuits/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_tops/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_bottoms/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_shoes/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_accessories/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_swim/': flattenChildLinkLabels('Sale'),
  '/collections/sale/category_final-sale/': flattenChildLinkLabels('Sale'),
};

function flattenChildLinks(rootLabel: String) {
  const rootMenuLink = DesktopMenuLinkTree.find((menuLink) => menuLink.label === rootLabel);

  const mapFn = ({ children, ...childLink }: MenuLink): Array<MenuLink> => {
    return children ? [childLink, ...children.flatMap(mapFn)] : [childLink];
  };

  return rootMenuLink?.children?.flatMap(mapFn).filter((childLink) => !!childLink.href) || [];
}

function flattenChildLinkLabels(rootLabel: String) {
  return flattenChildLinks(rootLabel).map((link) => link.label);
}

export const CollectionFilterMapping: CollectionFilterMappingType = {
  swim: {
    swim: {
      label: 'All Swimwear',
      icon: SwimAllIcon,
      href: '/collections/swim/',
    },
    trend_bandeau: {
      label: 'Bandeau',
      icon: SwimBandeauIcon,
      href: '/collections/swim/trend_bandeau',
    },
    trend_bralette: {
      label: 'Bralette',
      icon: SwimBraletteIcon,
      href: '/collections/swim/trend_bralette',
    },
    'trend_scoop-neck': {
      label: 'Scoop Neck',
      icon: SwimScoopNeckIcon,
      href: '/collections/swim/trend_scoop-neck',
    },
    trend_triangle: {
      label: 'Triangle',
      icon: SwimTriangleIcon,
      href: '/collections/swim/trend_triangle',
    },
    trend_underwire: {
      label: 'Underwire',
      icon: SwimUnderwireIcon,
      href: '/collections/swim/trend_underwire',
    },
    trend_cheeky: {
      label: 'Cheeky',
      icon: SwimCheekyIcon,
      href: '/collections/swim/trend_cheeky',
    },
    trend_classic: {
      label: 'Classic',
      icon: SwimClassicIcon,
      href: '/collections/swim/trend_classic',
    },
    'trend_high-cut': {
      label: 'High Cut',
      icon: SwimHighCutIcon,
      href: '/collections/swim/trend_high-cut',
    },
    'trend_tie-up': {
      label: 'Tie-Up',
      icon: SwimTieUpIcon,
      href: '/collections/swim/trend_tie-up',
    },
    // 'trend_high-waisted': {
    //   label: 'High Waisted',
    //   icon: SwimHighWaistedIcon,
    //   href: '/collections/swim/trend_high-waisted',
    // },
    'one-pieces': {
      label: 'One Piece',
      icon: SwimOnePieceIcon,
      href: '/collections/one-pieces',
    },
  },
};

export const ParentCollectionAssociations = Object.keys(CollectionFilterMapping).reduce(
  (parentAccumulator, parentMapping) => {
    const currentChildren = Object.keys(CollectionFilterMapping[parentMapping]).reduce(
      (acc, collectionObject) => {
        acc[CollectionFilterMapping[parentMapping][collectionObject].href] = parentMapping;
        return acc;
      },
      {} as { [key: string]: string },
    );
    return { ...parentAccumulator, ...currentChildren };
  },
  {},
);

export enum MegaMenuRoutes {
  'ALL NEW' = '/collections/new/',
  'ALL DRESSES' = '/collections/dresses/',
  'NEW DRESSES' = '/collections/new/category_dresses/',
  'NEW PLAYSUITS' = '/collections/new/category_playsuits/',
  'NEW TOPS' = '/collections/new/category_tops/',
  'NEW BOTTOMS' = '/collections/new/category_bottoms/',
  'NEW SWIM' = '/collections/new/category_swim/',
  'NEW SHOES' = '/collections/new/category_shoes/',
  'NEW ACCESSORIES' = '/collections/new/category_accessories/',
  'NEW SETS & CO-ORDS' = '/collections/new/category_co-ords/',
  'NEW EXCLUSIVES' = '/collections/new/category_exclusive/',
  'MINI DRESSES' = '/collections/mini-dresses/',
  'MIDI DRESSES' = '/collections/midi-dresses/',
  'MAXI DRESSES' = '/collections/maxi-dresses/',
  'SLIP DRESSES' = '/collections/slip-dresses/',
  'SEQUIN DRESSES' = '/collections/sequin-dress/',
  'BODYCON DRESSES' = '/collections/bodycon-dress/',
  'PRINTED DRESSES' = '/collections/printed-dresses/',
  'BACKLESS DRESSES' = '/collections/backless-dress/',
  'OFF THE SHOULDER DRESSES' = '/collections/off-the-shoulder-dress/',
  'LONG SLEEVE DRESSES' = '/collections/long-sleeve-dress/',
  'LACE DRESSES' = '/collections/lace-dress/',
  'PARTY DRESSES' = '/collections/party-dresses/',
  'BRIDESMAID & WEDDING GUEST' = '/collections/dresses/occasion_bridesmaid-&-wedding-guest/',
  'FORMAL & COCKTAIL' = '/collections/dresses/occasion_formal-&-cocktail/',
  'BEACH & HOLIDAY' = '/collections/dresses/occasion_beach-&-holiday/',
  'BRUNCH' = '/collections/dresses/occasion_brunch/',
  'DATE NIGHT' = '/collections/dresses/occasion_date-night/',
  'DAY DRESSES' = '/collections/day-dresses/',
  'FESTIVAL' = '/collections/dresses/occasion_festival/',
  'RACING' = '/collections/dresses/occasion_racing/',
  'WORK' = '/collections/dresses/occasion_work/',
  'ALL PLAYSUITS' = '/collections/playsuits/',
  'PARTY PLAYSUITS' = '/collections/party-playsuits/',
  'PRINTED PLAYSUITS' = '/collections/printed-playsuits/',
  'JUMPSUITS' = '/collections/jumpsuits/',
  'ALL TOPS' = '/collections/tops/',
  'CROP TOPS' = '/collections/crop-tops/',
  'SLEEVELESS TOPS' = '/collections/sleeveless-tops/',
  'SHORT SLEEVED TOPS' = '/collections/short-sleeved-tops/',
  'LONG SLEEVED TOPS' = '/collections/long-sleeved-tops/',
  'BASICS' = '/collections/basics/',
  'BODYSUITS & BRALETS' = '/collections/bodysuits-bralets/',
  'BLAZERS' = '/collections/blazers/',
  'KNITS' = '/collections/knits/',
  'SETS & CO-ORDS' = '/collections/sets/',
  'OUTERWEAR' = '/collections/outerwear/',
  'ALL BOTTOMS' = '/collections/bottoms/',
  'ALL SKIRTS' = '/collections/skirts/',
  'MINI SKIRTS' = '/collections/skirts/length_mini/',
  'MIDI SKIRTS' = '/collections/skirts/length_midi/',
  'MAXI SKIRTS' = '/collections/skirts/length_maxi/',
  'DENIM SKIRTS' = '/collections/skirts/color_denim/',
  'SKORTS' = '/collections/skorts/',
  'ALL SHORTS' = '/collections/shorts/',
  'DENIM SHORTS' = '/collections/shorts/color_denim/',
  'FASHION SHORTS' = '/collections/fashion-shorts/',
  'ALL PANTS' = '/collections/pants/',
  'LEGGINGS' = '/collections/leggings/',
  'SKINNY PANTS' = '/collections/skinny-pants/',
  'TAILORED PANTS' = '/collections/tailored-pants/',
  'WIDE LEG PANTS' = '/collections/wide-leg-pants/',
  'JEANS' = '/collections/jeans/',
  'ALL BACK IN STOCK' = '/collections/back-in-stock/',
  'BACK IN DRESSES' = '/collections/back-in-stock/category_dresses/',
  'BACK IN PLAYSUITS' = '/collections/back-in-stock/category_playsuits/',
  'BACK IN TOPS' = '/collections/back-in-stock/category_tops/',
  'BACK IN BOTTOMS' = '/collections/back-in-stock/category_bottoms/',
  'BACK IN ACCESSORIES' = '/collections/back-in-stock/category_accessories/',
  'BACK IN SWIM' = '/collections/back-in-stock/category_swim/',
  'ALL SHOES' = '/collections/shoes/',
  'HEELS' = '/collections/heels/',
  'MULES' = '/collections/mules/',
  'SLIDES' = '/collections/slides/',
  'BOOTS' = '/collections/boots/',
  'SANDALS' = '/collections/sandals/',
  'FLATS' = '/collections/flats/',
  'ALL ACCESSORIES' = '/collections/accessories/',
  'ALL JEWELLERY' = '/collections/jewellery/',
  'NECKLACES' = '/collections/necklaces/',
  'EARRINGS' = '/collections/earrings/',
  'BRACELETS' = '/collections/bracelets/',
  'RINGS' = '/collections/rings/',
  'BREAST STYLING ESSENTIALS' = '/collections/accessories/category_magic-bra/',
  'BEAUTY & WELLNESS' = '/collections/beauty/',
  'BAGS' = '/collections/bags/',
  'SUNGLASSES' = '/collections/sunglasses/',
  'HAIR & HEADWEAR' = '/collections/headwear/',
  'SWIM ACCESSORIES' = '/collections/swim-accessories/',
  'ALL SALE' = '/collections/sale/',
  'NEW ADDED TO SALE' = '/collections/sale/category_new/',
  'SALE DRESSES' = '/collections/sale/category_dresses/',
  'SALE PLAYSUITS' = '/collections/sale/category_playsuits/',
  'SALE TOPS' = '/collections/sale/category_tops/',
  'SALE BOTTOMS' = '/collections/sale/category_bottoms/',
  'SALE SHOES' = '/collections/sale/category_shoes/',
  'SALE ACCESSORIES' = '/collections/sale/category_accessories/',
  'SALE SWIM' = '/collections/sale/category_swim/',
  'FINAL SALE' = '/collections/sale/category_final-sale/',
  'ALL SWIM' = '/collections/swim/',
  'ONE-PIECES' = '/collections/one-pieces/',
  'SWIM TOPS' = '/collections/swim-tops/',
  'SWIM BOTTOMS' = '/collections/swim-bottoms/',
  'COVER UPS' = '/collections/cover-ups/',
  'ALL EXCLUSIVES' = '/collections/exclusive/',
  'HELLO MOLLY' = '/collections/exclusive/brand_hello-molly/',
  'DEAR EMILIA' = '/collections/exclusive/brand_dear-emilia/',
  'SUR BELLE' = '/collections/exclusive/brand_sur-belle/',
  'HELLO MOLLY SWIM' = '/collections/swim/brand_hello-molly/',
  'UNDERCOVER STYLE HELPERS' = '/collections/accessories/brand_undercover-style-helpers/',
  'HELLO MINI' = '/collections/exclusive/brand_hello-mini/',
  'HOLIDAY STORE' = '/collections/holiday-store/',
  'SELF LOVE' = '/collections/self-love/',
  'ALL WEDDING PARLOUR' = '/collections/wedding-parlour/',
  'BRIDESMAIDS' = '/collections/wedding-parlour/category_bridesmaids/',
  'BRIDE' = '/collections/wedding-parlour/category_bride/',
  'BACHELORETTE' = '/collections/wedding-parlour/category_bachelorette/',
  'WEDDING GUEST' = '/collections/wedding-parlour/category_wedding-guest/',
  'WEDDING PARLOUR' = '/collections/wedding-parlour',
  'HELLO MOLLY BASE' = '/collections/hello-molly-basics',
  'HELLO MOLLY DNM' = '/collections/hello-molly/trend_denim',
}

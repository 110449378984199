import { gql } from '../../__generated__/gql';

export const GET_CUSTOMER = gql(`
  query getCustomer ($customerAccessToken: String!) {
    customer (customerAccessToken: $customerAccessToken) {
      id
      email
      displayName
      firstName
      lastName
      phone
      acceptsMarketing
      updatedAt
      createdAt
      defaultAddress {
        id
        firstName
        lastName
        phone
        company
        address1
        address2
        city
        province
        country
        zip
      }
      addresses (first: 16) {
        edges {
          node {
            id
            firstName
            lastName
            phone
            company
            address1
            address2
            city
            province
            country
            zip
          }
        } 
      }
    }
  }
`);

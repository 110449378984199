import { gql } from '../../__generated__/gql';

export const GET_CUSTOMER_ORDERS = gql(`
  query getCustomerOrders ($customerAccessToken: String!) {
    customer (customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      email
      orders (first: 32) {
        edges {
          node {
            id
            name
            orderNumber
            processedAt
            fulfillmentStatus
            subtotalPrice {
              currencyCode
              amount
            }
            totalShippingPrice {
              currencyCode
              amount
            }
            totalPrice {
              currencyCode
              amount
            }
            lineItems (first: 32) {
              edges {
                node {
                  title
                  quantity
                  originalTotalPrice {
                    currencyCode
                    amount
                  }
                  discountedTotalPrice {
                    currencyCode
                    amount
                  }
                  variant {
                    title
                    price {
                      currencyCode
                      amount
                    }
                    image {
                      id
                      url
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          startCursor
          hasPreviousPage
          endCursor
        }
      }
    }
  }
`);

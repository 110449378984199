import { DownChevronIcon, Menu } from '@ui/components/core';
import { Text } from '@ui/components/shared/typography';
import env from '@ui/env';
import cn from '@ui/utils/cn';

const BannerCurrencyDropdown = () => (
  <div className="shrink-0 flex">
    <Text className="hidden mr-2 md:block">YOU&apos;RE SHOPPING IN:</Text>
    <Menu
      zIndex={9999}
      withinPortal
      classNames={{
        dropdown: 'px-0 py-1 rounded-none shadow-none border-black bg-white shrunk:!opacity-0',
        item: 'px-3 py-1 text-[12px] rounded-none underline-offset-[6px] data-[hovered]:underline data-[hovered]:bg-white',
      }}
    >
      <Menu.Target>
        <button
          className={cn(
            'flex items-center justify-center',
            'text-[12px] font-normal space-x-1 group',
            'focus:outline-none',
          )}
        >
          {env.NEXT_PUBLIC_REGION === 'NZ' && <span>$NZD</span>}
          {env.NEXT_PUBLIC_REGION === 'AU' && <span>$AUD</span>}
          {env.NEXT_PUBLIC_REGION === 'US' && <span>$USD</span>}
          <DownChevronIcon className="group-data-[expanded]:rotate-180 transition" width={16} height={16} />
        </button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component="a"
          href="https://hellomolly.co.nz/"
          data-selected={env.NEXT_PUBLIC_REGION === 'NZ' || undefined}
          className="data-[selected]:underline"
        >
          $NZD
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://hellomolly.com.au/"
          data-selected={env.NEXT_PUBLIC_REGION === 'AU' || undefined}
          className="data-[selected]:underline"
        >
          $AUD
        </Menu.Item>
        <Menu.Item
          component="a"
          href="https://www.hellomolly.com/"
          data-selected={env.NEXT_PUBLIC_REGION === 'US' || undefined}
          className="data-[selected]:underline"
        >
          $USD
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  </div>
);

export default BannerCurrencyDropdown;

import axios from 'axios';
import env from '@ui/env';
import { getWishlist } from '@ui/axios/wishlistKing/getWishlist';

export type AddWishlistProductArgs = {
  customerId?: string;
  sessionId: string;
  productId: string | number;
  variantId: string | number;
};

export const addWishlistProduct = async ({ customerId, sessionId, productId, variantId }: AddWishlistProductArgs) => {
  const currentProductId = typeof productId === 'string' ? parseInt(productId) : productId;
  const currentVariantId = typeof variantId === 'string' ? parseInt(variantId) : variantId;
  const { wishlistKingData } = await getWishlist({ customerId, sessionId });
  const { products, permaId: wishlistId } = wishlistKingData;
  // check if product exists in wishlist, return if it does
  const hasProd = !!products.find((prod) => prod.productId === currentProductId);
  if (hasProd) return;

  const { data } = await axios.request({
    method: 'POST',
    url: `https://api.appmate.io/v1/shop/${env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/wishlist/${wishlistId}/wishlist-item`,
    headers: {
      'content-type': 'application/json',
      'x-appmate-cid': `${customerId ? customerId : false}`,
      'x-appmate-sid': sessionId,
    },
    data: { productId: currentProductId, variantId: currentVariantId },
  });

  const { data: wishlistProduct } = data;

  return { wishlistProduct };
};

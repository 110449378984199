import { gql } from '../../__generated__/gql';

export const GET_CUSTOMER_ORDER = gql(`
  query getCustomerOrder ($customerAccessToken: String!, $orderId: String!) {
    customer (customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      orders (first: 1, query: $orderId) {
        edges {
          node {
            id
            name
            orderNumber
            processedAt
            fulfillmentStatus
            statusUrl
            customerUrl
            successfulFulfillments {
              trackingCompany
              trackingInfo {
                number
                url
              }
            }
            shippingAddress {
              id
              firstName
              lastName
              phone
              company
              address1
              address2
              city
              province
              country
              zip
            }
            subtotalPrice {
              currencyCode
              amount
            }
            totalShippingPrice {
              currencyCode
              amount
            }
            totalPrice {
              currencyCode
              amount
            }
            lineItems (first: 32) {
              edges {
                node {
                  title
                  quantity
                  originalTotalPrice {
                    currencyCode
                    amount
                  }
                  discountedTotalPrice {
                    currencyCode
                    amount
                  }
                  variant {
                    title
                    price {
                      currencyCode
                      amount
                    }
                    image {
                      id
                      url
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

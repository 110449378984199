import React from 'react';

const MyOrdersIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14342 4.21484H17.8577L20.2863 7.8577V18.7863H5.71484V7.8577L8.14342 4.21484Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.001 4.21484V7.8577" stroke="black" />
    <path d="M5.71484 7.85742H20.2863" stroke="black" />
  </svg>
);

export default MyOrdersIcon;

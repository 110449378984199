import { ServerProductProps } from '@ui/nextServer';
import cn from '@ui/utils/cn';
import { unescape } from 'lodash';
import Link from 'next/link';

type ProductBrandFromTagsProps = {
  className?: string;
  tags: ServerProductProps['props']['product']['tags'];
};

export default function ProductBrandFromTags({ className, tags }: ProductBrandFromTagsProps) {
  const brandAsTag = tags?.find((tag) => tag.startsWith('brand_'));

  if (!brandAsTag) return null;

  const brandHandle = brandAsTag.split('_').slice(1).join(' ');
  const brand = unescape(brandHandle.replace(/-/g, ' '));

  return (
    <div className={cn('text-[10px] text-[#727272] tracking-[0.55px] uppercase', className)}>
      <Link href={`/collections/${brandHandle}`}>{brand}</Link>
    </div>
  );
}

import axios from 'axios';
import env from '@ui/env';
import { createWishlist } from '@ui/axios/wishlistKing/createWishlist';
import { mapWishlistData } from '@ui/helpers/mapWishlistData';
import { Wishlist } from '@ui/types/contextObjects';
import { GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';

type GetWishlistQueryReturnType = {
  success: boolean;
  data: Wishlist[];
};

type GetWishlistArgs = {
  customerId?: string;
  sessionId: string;
};

export const getWishlist = async ({ customerId, sessionId }: GetWishlistArgs) => {
  const { data } = await axios.request<GetWishlistQueryReturnType>({
    method: 'GET',
    url: `https://api.appmate.io/v1/shop/${env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/wishlist`,
    headers: {
      'content-type': 'application/json',
      'x-appmate-cid': customerId,
      'x-appmate-sid': sessionId,
    },
  });

  const { data: wishlistResponse } = data;

  const wishlist = wishlistResponse.length > 0 ? wishlistResponse[0] : await createWishlist({ customerId, sessionId });

  const wishlistFrontEnd = mapWishlistData(wishlist);
  const wishlistProducts = Object.values(wishlistFrontEnd.products);

  if (env.MULTICURRENCY_FEATURE && wishlistProducts.length > 0) {
    const productsPricing = await GetProductsPricing({
      first: wishlistProducts.length,
      query: wishlistProducts.map((p) => `id:${p.id}`).join(' OR '),
      country: getCustomerCountry(),
    });

    wishlistProducts.forEach((product) => {
      const productEdges = productsPricing.data?.products.edges || [];
      const productPrice = productEdges.find((p) => p.node.id.split('/').pop() === product.id.toString());
      if (!productPrice) return;
      product.price = productPrice.node.priceRange.maxVariantPrice.amount;
      product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
      product.msrp = productPrice.node.compareAtPriceRange.maxVariantPrice.amount;
    });
  }

  return wishlistFrontEnd;
};

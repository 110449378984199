import React from 'react';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import { Box } from '../../../core';
import { FooterMenu } from '../../../shared';
import { FooterMenuHeadingLabels, FooterMenuLabels } from '@ui/types/mappings/FooterMenuMappings.type';
import env from '@ui/env';

const Footer = (): React.ReactElement => (
  <Box bottom={0}>
    {env.NEWSLETTER_FEATURE && <NewsletterSection />}
    <FooterMenu menuHeadingLabels={FooterMenuHeadingLabels} menuLabels={FooterMenuLabels} />
  </Box>
);

export default Footer;

import RunQuery from '../../utils/RunQuery.ts';
import { CombinedError } from '@urql/core';
import { GET_CUSTOMER_ORDERS } from '../../documents/queries';
import type { GetCustomerOrdersQuery, GetCustomerOrdersQueryVariables } from '../../__generated__/graphql';

export async function GetCustomerOrders({ customerAccessToken }: GetCustomerOrdersQueryVariables): Promise<{
  data: GetCustomerOrdersQuery | undefined;
  error: CombinedError | undefined;
}> {
  const { data, error } = await RunQuery(GET_CUSTOMER_ORDERS, { customerAccessToken: customerAccessToken });
  return { data, error };
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { useCartStore } from '@ui/store.export';
import { GetCart } from '@client-shopify/gql/storefront/api/queries';
import { authSelectors } from '@ui/store/authStore';
import { auContent, nzContent } from '@content/data';
import env from '@ui/env';

const content = env.NEXT_PUBLIC_REGION === 'AU' ? auContent : nzContent;

declare global {
  interface Window {
    ScarabQueue: any[];
  }
}

export function useScarabQueue() {
  const [client, setClient] = React.useState<{}>({});
  const { auth } = authSelectors;
  const customerEmail = auth.use.customer()?.customer?.email || '';
  const cartId = useCartStore((state) => state?.cartId);

  const productViewRef = useRef<(path: string) => void>(() => {});
  const categoryViewRef = useRef<(path: string) => void>(() => {});
  const cartData = cartId ? GetCart({ cartId, buyerIp: '' }).then((res) => res?.cart) : null;
  const cartRef = useRef<(cart: any) => void>(() => {});
  const setEmailRef = useRef<(email: string) => void>(() => {});
  const setCartIdRef = useRef<(cartId: string | null | undefined) => void>(() => {});
  const searchTermRef = useRef<(path: string) => void>(() => {});

  const emarsysInnerScarabScriptFunc = content['Tag.emarsysInnerScarabScriptFunc'];
  const emarsysInnerScriptFunc = content['Tag.Emarsys.WebExtend'];

  const productProps = useRef<HTMLElement | null>();

  const router = useRouter();

  const addScript = (id: string, scriptContent: string) => {
    if (document.getElementById(id)) return;
    const script = document.createElement('script');
    script.id = id;
    script.innerHTML = scriptContent;
    document.head.appendChild(script);
  };

  React.useEffect(() => {
    setClient(window);
  }, []);

  React.useEffect(() => {
    if (!client) return;
    if (!emarsysInnerScarabScriptFunc || !emarsysInnerScriptFunc) return;
    if (router.asPath.includes('products')) {
      productProps.current = document.getElementById('__NEXT_DATA__');
      if (!productProps.current) return;
      productViewRef.current = (path: string) => {
        if (!path.includes('products') || path.startsWith('/search/products')) return;
        if (!productProps.current?.textContent) return;
        if (!JSON.parse(productProps.current.textContent).props.pageProps.id) return;
        window.ScarabQueue = window.ScarabQueue || [];
        window.ScarabQueue.push([
          'view',
          `g/${JSON.parse(productProps.current.textContent).props.pageProps.product.id.split('/').pop()}`,
        ]);
      };
    }
  }, [router.asPath]);

  React.useEffect(() => {
    if (!client) return;
    if (!emarsysInnerScarabScriptFunc || !emarsysInnerScriptFunc) return;
    productProps.current = document.getElementById('__NEXT_DATA__');

    cartRef.current = (cart: {}[]) => {
      if (!cart) return;
      window.ScarabQueue = window.ScarabQueue || [];
      window.ScarabQueue.push(['cart', cart]);
    };

    productViewRef.current = (path: string) => {
      if (!path.includes('products')) return;

      if (!productProps.current?.textContent) return;
      if (!JSON.parse(productProps.current.textContent).props.pageProps.id) return;
      try {
        window.ScarabQueue = window.ScarabQueue || [];
        window.ScarabQueue.push([
          'view',
          `g/${JSON.parse(productProps.current.textContent).props.pageProps.product.id.split('/').pop()}`,
        ]);
      } catch (e) {}
    };

    categoryViewRef.current = (path: string) => {
      if (path.includes('products')) return;
      if (!path.includes('collections')) return;
      const parts = path.split('/');
      const collectionsIndex = parts.indexOf('collections');
      if (collectionsIndex !== -1 && collectionsIndex + 1 < parts.length) {
        const category = parts[collectionsIndex + 1].replace(/-/g, ' ');
        const capitalizeFirstLetterOfEachWord = (category: string) =>
          category
            ? category
                .split(' ')
                .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
                .join(' ')
            : '';
        window.ScarabQueue = window.ScarabQueue || [];
        window.ScarabQueue.push(['category', capitalizeFirstLetterOfEachWord(category)]);
      }
    };

    setEmailRef.current = (email: string) => {
      if (!email) return;
      window.ScarabQueue = window.ScarabQueue || [];
      window.ScarabQueue.push(['setEmail', email]);
    };

    setCartIdRef.current = (cartId: string | null | undefined) => {
      if (!cartId) return;
      window.ScarabQueue = window.ScarabQueue || [];
      window.ScarabQueue.push(['tag', cartId]);
    };

    searchTermRef.current = (path: string) => {
      if (!path.includes('search')) return;
      const term = path.split('/').pop();
      window.ScarabQueue = window.ScarabQueue || [];
      window.ScarabQueue.push(['searchTerm', term]);
    };
  }, [cartData]);

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      (async () => {
        if (!emarsysInnerScarabScriptFunc || !emarsysInnerScriptFunc) return;
        addScript('scarab-js-api', emarsysInnerScarabScriptFunc);
        addScript('web-emarsys-sdk', emarsysInnerScriptFunc);
        const res = await cartData;
        if (cartData) {
          if (cartRef.current)
            cartRef.current(
              res?.cartLines?.map((item) => ({
                item: item.variantId.split('/').pop(),
                quantity: item.quantity,
                price: Number(item.costPerQuantity.amount),
              })),
            );
          if (setEmailRef.current) setEmailRef.current(customerEmail);
          if (setCartIdRef.current)
            setCartIdRef.current(cartId?.replaceAll(':', '%3A').replaceAll('/', '%2F').replaceAll('?', '%3F'));
          if (productViewRef.current) productViewRef.current(url);
          if (categoryViewRef.current) {
            if (url.includes('?')) {
              const urlParts = url.split('?');
              categoryViewRef.current(urlParts[0]);
            } else {
              categoryViewRef.current(url);
            }
          }
          if (searchTermRef.current) searchTermRef.current(url);
          window.ScarabQueue = window.ScarabQueue || [];
          window.ScarabQueue.push(['go']);
        } else {
          if (setEmailRef.current) setEmailRef.current(customerEmail);
          if (productViewRef.current) productViewRef.current(url);
          if (categoryViewRef.current) categoryViewRef.current(url);
          if (searchTermRef.current) searchTermRef.current(url);
          window.ScarabQueue = window.ScarabQueue || [];
          window.ScarabQueue.push(['go']);
        }
      })();
    };

    handleRouteChange(router.asPath);
  }, [router.asPath]);

  return {};
}

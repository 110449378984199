interface ProductCore {
  uid: string;
  sku: string;
  name: string;
  brand: string;
  price: number;
  msrp: number;
  imageUrl?: string;
  currency?: string;
}

interface ProductAttributes {
  handle: string;
  ss_id: string;
  variant_sku: string;
  ss_sizes: string;
  metafields: string;
  images: string;
  published_at: string;
  tags: Array<string>;
}

export interface Product {
  mappings: { core: ProductCore };
  attributes: ProductAttributes;
}

export interface SearchProduct {
  id: string;
  uid: string;
  sku: string;
  handle: string;
  title: string;
  brand: string;
  ss_sizes: string;
  variant_sku: string;
  price: number;
  imageUrl: string;
  metafields?: string;
  images: string;
  mappings: Product['mappings'];
  published_at: string;
  tags: Array<string>;
  variant_compare_at_price?: number;
  currency?: string;
}

export const recommendationProductsToSearchProducts = (products: Array<Product>) => {
  const searchProductArray: Array<SearchProduct> = [];
  products.forEach((product) => {
    const {
      mappings: { core },
      attributes,
    } = product;

    searchProductArray.push({
      id: attributes.ss_id,
      uid: core.uid,
      sku: core.sku,
      handle: attributes.handle,
      title: core.name,
      brand: core.brand,
      metafields: attributes.metafields,
      ss_sizes: attributes.ss_sizes,
      variant_sku: attributes.variant_sku,
      price: core.price,
      imageUrl: core.imageUrl || '',
      images: attributes.images,
      mappings: { core },
      published_at: attributes.published_at,
      tags: attributes.tags,
      variant_compare_at_price: core.msrp,
      currency: core.currency,
    });
  });

  return searchProductArray;
};

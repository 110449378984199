import axios from 'axios';
import env from '@ui/env';
import { getWishlist } from '@ui/axios/wishlistKing/getWishlist';

export type RemoveWishlistProductArgs = {
  customerId?: string;
  sessionId: string;
  productId: string | number;
};

export const removeWishlistProduct = async ({ customerId, sessionId, productId }: RemoveWishlistProductArgs) => {
  const currentProductId = typeof productId === 'string' ? parseInt(productId) : productId;
  const { wishlistKingData } = await getWishlist({ customerId, sessionId });
  const { products, permaId: wishlistId } = wishlistKingData;
  // check if product exists in wishlist, return if it doesn't
  const currentProd = products.find((prod) => prod.productId === currentProductId);
  if (!currentProd) return;

  const { data } = await axios.request({
    method: 'DELETE',
    url: `https://api.appmate.io/v1/shop/${env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/wishlist/${wishlistId}/wishlist-item/${currentProd.wishlistItemId}`,
    headers: {
      'content-type': 'application/json',
      'x-appmate-cid': `${customerId ? customerId : false}`,
      'x-appmate-sid': sessionId,
    },
  });

  if (!data.success) throw new Error('Could not remove the product from the wishlist');

  return true;
};

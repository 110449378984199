import env from '@ui/env';

type MoneyProps = {
  value: number | string | undefined;
  currency?: string;
  className?: string;
};

export default function Money({ value, currency, className }: MoneyProps) {
  return value ? (
    <span className={className}>{formatMoney(typeof value === 'number' ? value : +value, currency)}</span>
  ) : (
    <span className={className}>{formatMoney(0, currency)}</span>
  );
}

export function formatMoney(value: number, currency?: string) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    style: 'decimal',
  });

  const symbol = currency ? `${currency}${getCurrencySymbol(currency)}` : `${env.NEXT_PUBLIC_REGION}$`;

  if (value < 0) {
    return `-${symbol}${formatter.format(Math.abs(value))}`;
  } else {
    return `${symbol}${formatter.format(Math.abs(value))}`;
  }
}

function getCurrencySymbol(currency: string) {
  const symbols: Record<string, string> = {
    AFA: '؋',
    ALL: 'Lek',
    DZD: 'دج',
    AOA: 'Kz',
    ARS: '$',
    AMD: '֏',
    AWG: 'ƒ',
    AUD: '$',
    AZN: 'm',
    BSD: 'B$',
    BHD: '.د.ب',
    BDT: '৳',
    BBD: 'Bds$',
    BYR: 'Br',
    BEF: 'fr',
    BZD: '$',
    BMD: '$',
    BTN: 'Nu.',
    BTC: '฿',
    BOB: 'Bs.',
    BAM: 'KM',
    BWP: 'P',
    BRL: 'R$',
    GBP: '£',
    BND: 'B$',
    BGN: 'Лв.',
    BIF: 'FBu',
    KHR: 'KHR',
    CAD: '$',
    CVE: '$',
    KYD: '$',
    XOF: 'CFA',
    XAF: 'FCFA',
    XPF: '₣',
    CLP: '$',
    CLF: 'CLF',
    CNY: '¥',
    COP: '$',
    KMF: 'CF',
    CDF: 'FC',
    CRC: '₡',
    HRK: 'kn',
    CUC: '$, CUC',
    CZK: 'Kč',
    DKK: 'Kr.',
    DJF: 'Fdj',
    DOP: '$',
    XCD: '$',
    EGP: 'ج.م',
    ERN: 'Nfk',
    EEK: 'kr',
    ETB: 'Nkf',
    EUR: '€',
    FKP: '£',
    FJD: 'FJ$',
    GMD: 'D',
    GEL: 'ლ',
    DEM: 'DM',
    GHS: 'GH₵',
    GIP: '£',
    GRD: '₯, Δρχ, Δρ',
    GTQ: 'Q',
    GNF: 'FG',
    GYD: '$',
    HTG: 'G',
    HNL: 'L',
    HKD: '$',
    HUF: 'Ft',
    ISK: 'kr',
    INR: '₹',
    IDR: 'Rp',
    IRR: '﷼',
    IQD: 'د.ع',
    ILS: '₪',
    ITL: 'L,£',
    JMD: 'J$',
    JPY: '¥',
    JOD: 'ا.د',
    KZT: 'лв',
    KES: 'KSh',
    KWD: 'ك.د',
    KGS: 'лв',
    LAK: '₭',
    LVL: 'Ls',
    LBP: '£',
    LSL: 'L',
    LRD: '$',
    LYD: 'د.ل',
    LTC: 'Ł',
    LTL: 'Lt',
    MOP: '$',
    MKD: 'ден',
    MGA: 'Ar',
    MWK: 'MK',
    MYR: 'RM',
    MVR: 'Rf',
    MRO: 'MRU',
    MUR: '₨',
    MXN: '$',
    MDL: 'L',
    MNT: '₮',
    MAD: 'MAD',
    MZM: 'MT',
    MMK: 'K',
    NAD: '$',
    NPR: '₨',
    ANG: 'ƒ',
    TWD: '$',
    NZD: '$',
    NIO: 'C$',
    NGN: '₦',
    KPW: '₩',
    NOK: 'kr',
    OMR: '.ع.ر',
    PKR: '₨',
    PAB: 'B/.',
    PGK: 'K',
    PYG: '₲',
    PEN: 'S/.',
    PHP: '₱',
    PLN: 'zł',
    QAR: 'ق.ر',
    RON: 'lei',
    RUB: '₽',
    RWF: 'FRw',
    SVC: '₡',
    WST: 'SAT',
    STD: 'Db',
    SAR: '﷼',
    RSD: 'din',
    SCR: 'SRe',
    SLL: 'Le',
    SGD: '$',
    SKK: 'Sk',
    SBD: 'Si$',
    SOS: 'Sh.so.',
    ZAR: 'R',
    KRW: '₩',
    SSP: '£',
    XDR: 'SDR',
    LKR: 'Rs',
    SHP: '£',
    SDG: '.س.ج',
    SRD: '$',
    SZL: 'E',
    SEK: 'kr',
    CHF: 'CHf',
    SYP: 'LS',
    TJS: 'SM',
    TZS: 'TSh',
    THB: '฿',
    TOP: '$',
    TTD: '$',
    TND: 'ت.د',
    TRY: '₺',
    TMT: 'T',
    UGX: 'USh',
    UAH: '₴',
    AED: 'إ.د',
    UYU: '$',
    USD: '$',
    UZS: 'лв',
    VUV: 'VT',
    VEF: 'Bs',
    VND: '₫',
    YER: '﷼',
    ZMK: 'ZK',
    ZWL: '$',
  };

  return symbols[currency] || '$';
}

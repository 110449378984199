import React from 'react';
import { QuickAddButton as QuickAddButtonStyles } from '@ui/components/styles/Button';
import { BagIcon } from '@ui/components/core';

type QuickAddButtonProps = {
  toggleSizes(): void;
  filled: boolean;
};

const QuickAddButton = ({ toggleSizes, filled }: QuickAddButtonProps): React.ReactElement => {
  const styles = QuickAddButtonStyles();

  return (
    <BagIcon
      width="28px"
      height="28px"
      className={`${styles.classes.addToBag} ${filled ? styles.classes.filled : ''}`}
      onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event.preventDefault();
        toggleSizes();
      }}
    />
  );
};

export default QuickAddButton;

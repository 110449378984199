import { createStyles } from '@mantine/core';

const QuickAddButton = createStyles((theme) => ({
  addToBag: {
    position: 'absolute',
    right: '0.5rem',
    bottom: '0.5rem',
    zIndex: 2,
    [theme.fn.largerThan('sm')]: {
      display: 'none',
      // On desktops hide the shopping bag showing the sizes
      // [`.${getStylesRef('slideWrapper')}:hover &`]: {
      //   display: 'none',
      // },
    },
  },
  filled: {
    fill: theme.colors.brand[4],
  },
}));

export default QuickAddButton;
